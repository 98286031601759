<template>
	<Fragment>
		<div class="tab-contents section-wrap gap64" v-if="usingMembership !== undefined && usingMembership !== null">
			<!-- // [ 0223 수정사항 ] -->
			<!-- @멤버십1 -->
			<div class="section mb-64">
				<div class="membership open">
					<div class="card">
						<div class="membership-card-header">
							<div class="group row">
								<div class="badge white round" :class="usingMembership.status == 'IDLE'">
									{{ membershipStatusMap[usingMembership.status] }}
								</div>
								<!--
								<div class="badge white round text-caution">초과사용중</div>
								<div class="badge white round text-grey">해지</div>
								<div class="tag auto-pay" v-if="payInfoVisible">
									<i class="ic-24 ic-card"></i>
									자동카드결제
								</div>
								-->
							</div>
							<h3 class="title mb-16">{{ usingMembership.baseChannelInfoModel.baseChannelModel.name }}</h3>
						</div>

						<div class="contents">
							<ul class="badge-group summary gap8">
								<li class="group row gap8 align-middle" v-if="usingMembership.baseChannelInfoModel.totalUserCount > 0">
									<span class="badge line normal">
										<span class="detail-txt">사용자</span>
										<span class="txt">{{ usingMembership.baseChannelInfoModel.totalUserCount || 0 }}인 </span>
									</span>
									<span class="txt" v-if="usingMembership.baseAddUserCnt > 0">
										+ {{ usingMembership.baseAddUserCnt }} 명
									</span>
								</li>
								<li class="group row gap8 align-middle" v-if="usingMembership.baseChannelInfoModel.totalQuota > 0">
									<span class="badge line normal">
										<span class="detail-txt">계약용량</span>
										<span class="txt"> {{ usingMembership.baseChannelInfoModel.totalQuota || 0 }}GB </span>
									</span>
									<span class="txt" v-if="usingMembership.baseAddQuota > 0">
										+ {{ usingMembership.baseAddQuota }} GB
									</span>
								</li>
								<li v-if="usingMembership.membershipChargeType !== 'FREE'">
									<span class="badge line normal duration">
										<span class="detail-txt">사용기간 {{ totUsePeriod }}</span>
										<template v-if="remainDay.remainMonth > 0">
											<span class="txt"> 잔여 {{ remainDay.remainMonth }}개월 {{ remainDay.remainDay }}일 </span>
										</template>
										<template v-else-if="remainDay.remainDay > 0">
											<span class="txt"> 잔여 {{ remainDay.remainDay }}일 </span>
										</template>
										<template v-else> </template>
									</span>
								</li>
							</ul>
							<!-- 옵션 : section open 상태에서만 display -->
							<div class="option">
								<strong>옵션 상품</strong>
								<table>
									<template v-if="usingMembership.baseChannelInfoModel.usingChannelItemModels.length == 0">
										<tr>
											<td>사용중인 옵션 상품이 없습니다.</td>
										</tr>
									</template>
									<template v-else>
										<template v-for="(item, index) in usingMembership.baseChannelInfoModel.usingChannelItemModels">
											<template v-if="item.visible && item.channelItemType == 'VIRTUAL_DOMAIN'">
												<tr :key="'virtualDoamin_' + index" v-for="(row, index) in getVirtualDomains()">
													<th class="tit">가상도메인{{ index + 1 }}</th>
													<td class="data">{{ row }}</td>
												</tr>
											</template>
											<tr :key="'etc_' + index" v-if="item.visible && item.channelItemType !== 'VIRTUAL_DOMAIN'">
												<th class="tit">
													{{ item.channelItemModel.displaySectionModel.displayName }}
												</th>
												<td class="data">{{ item.usingCount }}개</td>
											</tr>
										</template>
									</template>
								</table>
							</div>
						</div>
						<button
							class="solid card-bottom primary"
							v-if="usingMembership.status == 'IDLE'"
							@click="goAutoExtensionPage"
						>
							추가/연장 주문하기
						</button>
					</div>
					<using-addon-status :usingMembership="usingMembership"></using-addon-status>
					<!-- 부가서비스 무료체험 -->
					<!--					<using-free-trial-addon></using-free-trial-addon>-->
					<!-- 유료전환 레이어 -->
					<!--					<converting-layer-->
					<!--						v-if="convertingLayerFlag"-->
					<!--						:usingMembership="usingMembership"-->
					<!--						:item="convertingServiceData"-->
					<!--						:endDate="usingMembership.baseChannelInfoModel.endDate"-->
					<!--						v-on:closeLayer="closeLayer"-->
					<!--					></converting-layer>-->
				</div>
				<div class="membership-details mb-40">
					<div class="contents">
						<div class="group column vertical-form condensed">
							<div class="group row col2">
								<div class="group row">
									<label class="form-label">
										<span class="txt">서비스 유형</span>
									</label>
									<div class="form-value">
										<span class="txt">{{ serviceNameMap[usingMembership.serviceType] }}</span>
									</div>
								</div>
								<div class="group row">
									<label class="form-label">
										<span class="txt">멤버십 상태</span>
									</label>
									<div class="form-value">
										<span class="txt">{{ membershipStatusMap[usingMembership.status] }}</span>
									</div>
								</div>
							</div>
							<div class="group row col2">
								<div class="group row">
									<label class="form-label">
										<span class="txt">기본서비스 명</span>
									</label>
									<div class="form-value">
										<span class="txt">{{ usingMembership?.baseChannelInfoModel?.baseChannelModel?.name || '' }}</span>
									</div>
								</div>
								<div class="group row">
									<label class="form-label">
										<span class="txt">멤버십 구분</span>
									</label>
									<div class="form-value">
										<span class="txt">{{ membershipChargeTypeMap[usingMembership.membershipChargeType] }}</span>
									</div>
								</div>
							</div>
							<div class="group row col2">
								<div class="group row">
									<label class="form-label">
										<span class="txt">서비스 시작일</span>
									</label>
									<div class="form-value">
										<span class="txt">{{ usingMembership.membershipBaseInfoModel.startDate }}</span>
									</div>
								</div>
								<div class="group row">
									<label class="form-label">
										<span class="txt">서비스 만료일</span>
									</label>
									<div class="form-value">
										<span class="txt">{{ usingMembership.membershipBaseInfoModel.expirationDate }}</span>
									</div>
								</div>
							</div>
							<div class="group row" v-if="payInfoVisible">
								<Creditcard
									:membership="this.usingMembership"
									v-on:changeAutoExtension="changeAutoExtension"
								></Creditcard>
							</div>
						</div>
					</div>
				</div>
				<!-- 약관 및 정책 동의 현황 -->
				<div
					class="membership-details"
					v-if="usingMembership.baseChannelInfoModel.baseChannelModel.termsSectionModel.termsModels.length > 0"
				>
					<h3 class="title">약관 및 정책 동의 현황</h3>
					<div class="contents">
						<table class="membership-table">
							<!-- com_tb_data large tb_membership_payment -->
							<thead>
								<tr>
									<th class="item">
										<span class="txt">약관타입</span>
									</th>
									<th class="item">
										<span class="txt">필수여부</span>
									</th>
									<th class="item">
										<span class="txt">동의여부</span>
									</th>
									<th class="period">
										<span class="txt">동의 / 철회 일자</span>
									</th>
									<th class="button">
										<span class="txt">상세보기</span>
									</th>
								</tr>
							</thead>
							<tbody class="">
								<tr v-for="(item, index) in this.termsList" :key="index" v-if="item.required == true">
									<td class="item">
										<span class="txt">{{ item.termsName }}</span>
									</td>
									<td class="item">
										<span class="txt">{{ item.required == true ? '필수' : '선택' }}</span>
									</td>
									<td class="item">
										<span class="txt">{{ item.useForm == true ? '동의' : '미동의' }}</span>
									</td>
									<td class="period" v-if="item.useForm == true">
										<span class="txt">{{ getFormateDate(usingMembership.baseChannelInfoModel.createdAt) }}</span>
									</td>
									<td class="button">
										<span class="txt">
											<button class="line mini" v-on:click="openTermsLayer(item)">보기</button>
										</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import UsingAddonStatus from '@/components/user/UsingAddonStatus.vue';
import Creditcard from '@/views/user/myStore/Creditcard';
import { membershipStatusMap, membershipChargeTypeMap, serviceNameMap, TERMS_MODAL_STYLE } from '@/utils/constants';
import termsTemplate from '@/components/apply/TermsDetail.vue';

export default {
	props: {
		membershipId: Number,
	},
	components: {
		Creditcard,
		UsingAddonStatus,
	},
	data() {
		return {
			convertingServiceData: null,
			convertingLayerFlag: false,
			moveType: '',
			usingMembership: null,
			freeTrialAddonList: [],
			remainDay: {},
			today: this.$moment().format('YYYY-MM-DD'),
			toggleText: 'more',
			autoExtension: false,
			termsList: [],
			membershipStatusMap: membershipStatusMap,
			membershipChargeTypeMap: membershipChargeTypeMap,
			serviceNameMap: serviceNameMap,
		};
	},
	computed: {
		payInfoVisible() {
			return (
				this.usingMembership.membershipChargeType !== 'FREE' &&
				(this.autoExtension ||
					(!this.autoExtension &&
						this.usingMembership.partnerSimpleModel.salesType !== 'RESELLER' &&
						this.usingMembership.enableAccessStore))
			);
		},
		totUsePeriod() {
			const fromDate = this.$moment(this.usingMembership.membershipBaseInfoModel.startDate);
			const toDate = this.$moment(this.usingMembership.membershipBaseInfoModel.expirationDate).format('YYYY-MM-DD');
			const today = this.$moment();
			if (today.isAfter(fromDate)) {
				return today.format('YYYY-MM-DD') + '~' + toDate;
			} else {
				return fromDate.format('YYYY-MM-DD') + '~' + toDate;
			}
		},
	},
	async created() {
		const data = await this.$store.dispatch('fetchMembership', this.membershipId);
		// 사용자 수 기본, 추가 separation
		// baseChannel, addonChannel 옵션 설정
		data.baseChannelInfoModel['foldFlag'] = true;
		await this.initOptionList(data.baseChannelInfoModel.usingChannelItemModels);
		data.addonChannelInfoModels.reduce((prevous, addonChannelInfoModel) => {
			return prevous.then(async () => {
				addonChannelInfoModel['foldFlag'] = true;
				await this.initOptionList(addonChannelInfoModel.usingChannelItemModels);
			});
		}, Promise.resolve());

		let baseDefaultUserCnt = parseInt(data.baseChannelInfoModel.baseChannelModel.defaultFeePolicyModel.defaultUserNum);

		data['baseDefaultUserCnt'] = baseDefaultUserCnt; // 기본서비스 기본 사용자수
		data['baseAddUserCnt'] = parseInt(data.baseChannelInfoModel.totalUserCount) - baseDefaultUserCnt; // 기본서비스 추가 사용자수

		// 용량 기본, 추가 separation
		let baseDefaultQuota = parseInt(data.baseChannelInfoModel.baseChannelModel.defaultFeePolicyModel.defaultVolume);
		data['baseDefaultQuota'] = baseDefaultQuota; // 기본서비스 기본 용량
		data['baseAddQuota'] = parseInt(data.baseChannelInfoModel.totalQuota) - baseDefaultQuota; // 기본서비스 기본 용량

		this.usingMembership = data;
		this.autoExtension = data.autoExtension;
		this.remainDay = await this.getRemainDays(); // 남은 사용일 수
		this.termsList = data?.baseChannelInfoModel?.baseChannelModel?.termsSectionModel?.termsModels || [];
		// 무료체험중인 부가서비스 조회.
		await this.setFreeTrialAddonList(this.usingMembership.baseChannelInfoModel.baseChannelModel.code);
	},
	methods: {
		openTermsLayer(item) {
			this.$modal.show(
				termsTemplate,
				{
					info: item,
				},
				Object.assign({}, TERMS_MODAL_STYLE, { name: 'termsTemplate', clickToClose: false }, { draggable: true }),
				{
					closed: () => {},
				},
			);
		},
		getFormateDate(date) {
			const fromDate = this.$moment(date);
			return fromDate.format('YYYY-MM-DD');
		},
		shorteningWords(str) {
			if (str.length > 9) {
				return str.substr(0, 9) + '...';
			}
			return str;
		},
		async getRemainDays() {
			// api 호출

			const today = new Date();
			const year = new String(today.getFullYear());
			let month = new String(today.getMonth() + 1);
			let day = new String(today.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			const todayStr = year + '-' + month + '-' + day;
			const data = await this.$store.dispatch('fetchRemainDays', {
				membershipId: this.usingMembership.id,
				startDate: todayStr,
			});

			const remainMonth = data.remainMonth;
			const remainDay = data.remainDay;

			return {
				remainMonth,
				remainDay,
			};
		},
		toggleOptionFold(options, item) {
			item.foldFlag = !item.foldFlag;
			for (let idx = 0; idx < options.length; idx++) {
				if (idx >= 5) {
					options[idx].visible = !options[idx].visible;
				}
			}
			this.toggleText = item.foldFlag ? 'more' : 'fold';
		},
		goPage(path) {
			this.$router.push(path);
		},
		closeLayer() {
			this.convertingLayerFlag = false;
		},
		async cloneObj(obj) {
			return JSON.parse(JSON.stringify(obj));
		},
		async setFreeTrialAddonList(param) {
			// 해당 기본서비스에 귀속된, 무료체험 정보들 setting
			var freeTrialAddonList = [];
			try {
				var { data } = await this.$store.dispatch('fetchAddonChannelList', param); // 부가서비스 리스트
				if (data == undefined || data == null || Object.keys(data).length == 0) {
					return;
				}
				freeTrialAddonList = data.filter((obj) => obj.freeTrialPolicyModel.usePolicy);

				// 현재 체험중인 무료체험 제외
				const usingFreeTrial = this.usingMembership.addonChannelInfoModels.filter(
					(obj) => obj.addonChannelModel.freeTrialPolicyModel.usePolicy,
				);
				await usingFreeTrial.reduce((previous, obj) => {
					return previous.then(async () => {
						let length = freeTrialAddonList.length;
						for (let i = 0; i < length; i++) {
							if (freeTrialAddonList[i].code == obj.addonChannelModel.code) {
								freeTrialAddonList.splice(i, 1);
								i--;
								length = freeTrialAddonList.length;
							}
						}
					});
				}, Promise.resolve());

				await freeTrialAddonList.reduce((previous, current) => {
					return previous.then(async () => {
						current['foldFlag'] = true;
						await this.initOptionList(current.optionMatrixModels);
					});
				}, Promise.resolve());

				this.freeTrialAddonList = freeTrialAddonList;
			} catch (error) {
				console.log(error);
			}
		},
		async initOptionList(items) {
			// 5개까지만 보여주기
			if (items) {
				for (let idx = 0; idx < items.length; idx++) {
					if (idx < 5) {
						items[idx]['visible'] = true;
					} else {
						items[idx]['visible'] = false;
					}
				}
			}
		},
		getVirtualDomains() {
			return this.usingMembership.representativeGroupware.virtualDomain;
		},
		changeAutoExtension(flag) {
			this.autoExtension = flag;
		},
		goAutoExtensionPage() {
			this.$router.push('/order/addExtension');
		},
	},
};
</script>

<style scoped></style>
