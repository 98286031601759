<template>
	<Fragment>
		<label class="form-label">
			<span class="txt">자동 연장 결제</span>
		</label>
		<div class="form-value">
			<div class="group-toggle sm" :class="{ off: !autoExtension }" v-on:click="autoExtensionChange(autoExtension)">
				<div class="toggle left">
					<input type="radio" name="testToggle2" checked />
					<label class="blind">사용</label>
				</div>
				<div class="toggle right">
					<input type="radio" name="testToggle2" />
					<label class="blind">사용안함</label>
				</div>
				<div class="toggle btn"></div>
			</div>
			<!-- 안내 -->
			<template v-if="autoExtension">
				<p class="text-sub primary mb-8 mt-8">
					<em class="bold">{{ nextPayDateStr }}</em>
					에 서비스 연장금액 <em class="bold">{{ monthlyFee | currency }}원(VAT별도)</em>이 결제될 예정입니다.
				</p>
			</template>
			<template v-else>
				<br />
			</template>
			<!-- 카드 등록 -->
			<div class="group row gap10 credit">
				<!-- 저장된 카드 -->
				<div class="card saved" v-for="(item, index) in autoExtensionCardList" :key="index">
					<button class="icon" v-on:click="autoExtensionChange(autoExtension)"><i class="ic-16 ic-close"></i></button>
					<div class="group column">
						<span class="card-label">CARD NUMBERS</span>
						<span class="card-value">{{ item.cardNumberDisplay }}</span>
					</div>
					<div class="group row">
						<div class="group column">
							<span class="card-label">EXPIRY DATE</span>
							<span class="card-value">**/**</span>
						</div>
						<div class="group column">
							<span class="card-label">CVC</span>
							<span class="card-value">***</span>
						</div>
					</div>
				</div>
				<!-- 카드추가 -->
				<!--				<button-->
				<!--					class="card add"-->
				<!--					v-if="autoExtensionCardList.length == 0"-->
				<!--					v-on:click="autoExtensionChange(autoExtension)"-->
				<!--				>-->
				<!--					카드등록-->
				<!--					<i class="ic-16 ic-add"></i>-->
				<!--				</button>-->
			</div>
		</div>
	</Fragment>
</template>

<script>
import mixinsAlert from '@/components/common/mixinsAlert';
export default {
	mixins: [mixinsAlert],
	props: {
		membership: {},
	},
	components: {},
	data() {
		return {
			nextPayDateStr: '',
			autoExtensionCardList: [],
			autoExtension: this.membership.autoExtension || false,
			conversionProduct: {},
			monthlyFee: 0,
		};
	},
	async created() {
		const customer = this.membership?.customer;
		if (this.membership.autoExtension) {
			await this.searchAutoExtensionCardList(customer.id); // 자동결제 카드 불러오기
			await this.setMonthlyFee();
		}
		await this.setNextPayDate(this.membership.membershipBaseInfoModel.expirationDate);
	},
	methods: {
		autoExtensionChange(autoExtension) {
			if (autoExtension) {
				this.alert({
					title: '자동 연장 해지',
					contents:
						'자동 연장을 해지하시겠습니까? <br /> ' +
						'해지 시 ' +
						this.membership.membershipBaseInfoModel.expirationDate +
						'일 까지 사용가능합니다.',
					buttons: [
						{
							text: '확인',
							callback: async () => {
								this.autoExtension = false;
								this.cancelAutoExtensionCard();
								this.$emit('changeAutoExtension', false);
							},
						},
						{
							text: '취소',
						},
					],
				});
			} else {
				this.alert({
					title: '자동 연장 신청',
					contents: '자동 연장을 신청하시겠습니까? <br /> 추가/연장페이지로 이동합니다.',
					buttons: [
						{
							text: '확인',
							callback: async () => {
								if (!this.membership.enableNextOrder) {
									this.alert({
										title: '서비스 추가/연장 불가',
										contents:
											'현재 멤버십 전환 예정으로 서비스 추가/연장이 불가합니다. <br />신청하신 멤버십 전환 내역은 주문/구매내역에서 확인하실 수 있습니다. <br />관련 문의사항은 영업담당자나 1577-3019로 문의주시기 바랍니다.',
									});
									return;
								}
								this.$router.push('/order/addExtension');
							},
						},
						{
							text: '취소',
						},
					],
				});
			}
		},
		setNextPayDate(expiredDate) {
			const membershipEndDate = new Date(expiredDate);
			membershipEndDate.setDate(membershipEndDate.getDate() - 1);
			let year = new String(membershipEndDate.getFullYear());
			let month = new String(membershipEndDate.getMonth() + 1);
			let day = new String(membershipEndDate.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			this.nextPayDateStr = year + '년' + month + '월' + day + '일';
		},
		async searchAutoExtensionCardList(customerId) {
			try {
				const obj = await this.$store.dispatch('searchAutoPaymentCardInfo', customerId);

				if (obj) {
					if (!obj.lastCardNo) {
						obj['cardNumberDisplay'] = '***-****-****';
					} else {
						obj['cardNumberDisplay'] = '***-****-' + obj.lastCardNo;
					}
					this.autoExtensionCardList.push(obj);
				}
				this.autoExtension = true;
			} catch (e) {
				console.log(e);
			}
		},
		async cancelAutoExtensionCard() {
			console.log(this.membership.id);
			await this.$store.dispatch('cancelAutoPaymentCardInfo', this.membership.id);
		},
		// 자동연장 case
		// 전환대상 o: 신요금제 멤버십을 기준으로 다시 계산한 결제예상금액을 가져온다.
		// 전환대상 x: 기존 멤버십의 결제예상금액을 가져온다.
		async setMonthlyFee() {
			await this.checkIsConversionMembership();
			if (this.conversionProduct.convertable) {
				//신요금제 전환
				const newMembership = await this.$store.dispatch('fetchConversionMembership', {
					membershipId: this.membership.id,
					baseChannelId: this.conversionProduct.conversionId,
				});
				this.monthlyFee = newMembership.convertedMonthlyFee;
			} else {
				this.monthlyFee = this.membership.monthlyFee;
			}
		},
		// 전환대상 요금제를 사용중인지 체크
		async checkIsConversionMembership() {
			const baseChannelId = this.membership.baseChannelInfoModel.baseChannelModel.id;
			this.conversionProduct = await this.$store.dispatch('checkBaseChannelIsConvertable', baseChannelId);
		},
	},
};
</script>

<style scoped></style>
