<template>
	<Fragment>
		<div class="container">
			<div class="body-contents system">
				<h2>멤버십 현황</h2>
				<div class="tab-wrap">
					<membership-tab @getSelected="getSelected"></membership-tab>
				</div>
				<membership-status
					v-if="this.selectMembershipId"
					:key="this.selectMembershipId"
					:membership-id="this.selectMembershipId"
				></membership-status>
			</div>
		</div>
	</Fragment>
</template>

<script>
import MembershipStatus from '@/views/user/myStore/MembershipStatus';
import MembershipTab from '@/components/common/MembershipTab';

export default {
	components: {
		MembershipStatus,
		MembershipTab,
	},
	data() {
		return {
			selectMembershipId: '',
		};
	},
	watch: {},
	async created() {},
	computed: {},
	methods: {
		getSelected(membershipId) {
			this.selectMembershipId = membershipId;
		},
	},
};
</script>
